import { Button, Form, Row } from "antd";
import { useEffect, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import TextEditor from "../components/TextEditor";
import { fetchSettings, getSetting, saveSettings } from "../store/reducers/settingSlice";
import { setSelectedMenu } from "../store/reducers/uiSlice";

function NewsPage(props) {
  const [form] = Form.useForm();
  const editorRef = useRef(null);
  const setting = useSelector((state) => state.setting.settings);
  const newsValue = useSelector((state) => state.setting.value);
  useEffect(() => {
    props.setSelectedMenu("7");
  }, []);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSettings());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getSetting("news"));
  }, [setting]);
  const save = () => {
    const longValue = editorRef.current.getContent();
    dispatch(saveSettings({ newValue: { Name: "news", longValue, Value: "news" }, oldValue: newsValue }));
  };
  return (
    <div id="users-page">
      <h2 className="title">News</h2>
      <TextEditor data={newsValue?.longValue} editorRef={editorRef}></TextEditor>
      <Form onFinish={save} form={form}>
        <Row justify="end">
          <Button type="primary" width="160px" style={{ marginTop: 20 }} htmlType="submit">
            Save
          </Button>
        </Row>
      </Form>
    </div>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    setSelectedMenu: (id) => dispatch(setSelectedMenu(id)),
  };
}
export default connect(null, mapDispatchToProps)(NewsPage);
