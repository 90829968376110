import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useState } from 'react';
import { Spin, Tooltip } from 'antd';

import * as roles from './../../services/constants/roles';
import Table from '../../components/Table';
import { setSelect, setUrl, setQuery } from '../../store/reducers/dataSlice';
import { setSelectedMenu } from '../../store/reducers/uiSlice';

const checkValidUrl = (url) => {
  return url?.startsWith('http') ? url : `//${url}`;
};

function ProductsPage(props) {
  // const dispatch = useDispatch();

  const [count, setCount] = useState(null);

  const [productTypeFilter, setProductTypeFilter] = useState([]);
  useEffect(() => {
    axios.get('/product-types?&_sort=Name:ASC').then((res) => {
      setProductTypeFilter(
        res.data.map((type) => {
          return { text: type.Name, value: type.id };
        }),
      );
    });
  }, []);

  const [vendorFilter, setVendorFilter] = useState([]);
  useEffect(() => {
    axios.get('/vendors?&_sort=Name:ASC').then((res) => {
      setVendorFilter(
        res.data.map((type) => {
          return { text: type.Name, value: type.id };
        }),
      );
    });
  }, []);

  useEffect(() => {
    props.setSelectedMenu('40');

    props.setUrl('/products');
    props.setSelect(
      `&Deleted=0&${
        props.roleId === roles.ADMIN_ROLE_ID ? '' : 'Buyable=true&'
      }`,
    );
    props.setQuery('&_sort=id:DESC&');
    // dispatch(getData());
    axios
      .get(
        `/products/count?&Deleted=0&${
          props.roleId === roles.ADMIN_ROLE_ID ? '' : 'Buyable=true&'
        }`,
      )
      .then((res) => setCount(res.data))
      .catch((e) => console.log(e));
  }, [props]);

  const adminColumns = [
    // {
    //   title: "Super Co op Commercial Cost",
    //   dataIndex: "CoOPReqularPrice",
    //   key: "CoOPReqularPrice",
    //   render: (text) => <div style={{ textAlign: "right" }}>{text ? `$${text.toFixed(2)}` : "N/A"}</div>,
    //   sorter: {},
    // },
    // {
    //   title: "Super Co op Commodity Price",
    //   dataIndex: "CoOPCommidityPrice",
    //   key: "CoOPCommidityPrice",
    //   render: (text) => <div style={{ textAlign: "right" }}>{text ? `$${text.toFixed(2)}` : "N/A"}</div>,
    //   sorter: {},
    // },
    {
      title: 'Commercial Cost',
      dataIndex: 'ReqularPrice',
      key: 'ReqularPrice',
      render: (text, rec) => (
        <div style={{ textAlign: 'right' }}>
          {text ? `$${text.toFixed(2)}` : 'N/A'}
        </div>
      ),
      sorter: {},
    },
    {
      title: 'PTV',
      dataIndex: 'PTV',
      key: 'PTV',
      render: (text) => (
        <div style={{ textAlign: 'right' }}>
          {text ? `$${text.toFixed(2)}` : 'N/A'}
        </div>
      ),
      sorter: {},
    },
    {
      title: 'Commodity Price',
      dataIndex: 'CommidityPrice',
      key: 'CommidityPrice',
      render: (text) => (
        <div style={{ textAlign: 'right' }}>
          {text ? `$${text.toFixed(2)}` : 'N/A'}
        </div>
      ),
      sorter: {},
    },
  ];

  const notAdminColumns = [
    {
      title: 'Commercial Cost',
      dataIndex: props.amCOOP ? 'CoOPReqularPrice' : 'ReqularPrice',
      key: 'ReqularPrice',
      render: (text, rec) =>
        text ? (
          <div style={{ textAlign: 'right' }}>{`$${text.toFixed(2)}`}</div>
        ) : (
          'N/A'
        ),
      sorter: {},
    },
    {
      title: 'PTV',
      dataIndex: 'PTV',
      key: 'PTV',
      render: (text) => (
        <div style={{ textAlign: 'right' }}>
          {text ? `$${text.toFixed(2)}` : 'N/A'}
        </div>
      ),
      sorter: {},
    },
    {
      title: 'Commodity Price',
      dataIndex: props.amCOOP ? 'CoOPCommidityPrice' : 'CommidityPrice',
      key: 'CommidityPrice',
      render: (text) =>
        text ? (
          <div style={{ textAlign: 'right' }}>{`$${text.toFixed(2)}`}</div>
        ) : (
          'N/A'
        ),
      sorter: {},
    },
  ];

  let columns = [
    {
      title: 'Code',
      dataIndex: 'Code',
      key: 'Code',
      render: (text, rec) =>
        text ? (
          <a
            target="_blank"
            href={`/products/product/${rec.id}`}
            style={{ color: 'black' }}
            rel="noreferrer"
          >
            {text}
          </a>
        ) : (
          'N/A'
        ),

      sorter: {},
      searchable: true,
    },
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'Name',
      render: (text) => (text ? text : 'N/A'),

      sorter: {},
      searchable: true,
    },
    {
      title: 'URL (Link to specs)',
      dataIndex: ['productAttachment', 'url'],
      key: 'specs',
      render: (text) =>
        text ? (
          <a
            className="btn"
            target="_blank"
            href={checkValidUrl(text)}
            style={{ padding: '3px 24px', background: '#97BE11' }}
            rel="noreferrer"
          >
            Spec
          </a>
        ) : (
          'N/A'
        ),
    },
    ...(props.roleId === roles.ADMIN_ROLE_ID ? adminColumns : notAdminColumns),

    {
      title: 'Lead Time',
      dataIndex: 'TimeLead',
      key: 'TimeLead',
      render: (text) =>
        text ? <div style={{ textAlign: 'center' }}>{text} days</div> : 'N/A',
      sorter: {},
    },
    {
      title: 'Vendor',
      dataIndex: 'VendorId',
      key: 'VendorId',
      render: (text, rec) => {
        return text && text.Name ? text.Name : 'N/A';
      },
      sorter: {},
      location: 'VendorId_in',
      searchable: true,
      filters: vendorFilter,
      sortLocation: 'VendorId.Name',
      // filterMultiple: false,
    },
    {
      title: 'Type',
      dataIndex: 'productType',
      key: 'productType',
      render: (text) => (text && text.Name ? text.Name : 'N/A'),
      location: 'productType_in',
      sortLocation: 'productType.Name',
      sorter: {},
      searchable: true,
      filters: productTypeFilter,
      // filterMultiple: false,
    },
    ...(props.roleId === roles.ADMIN_ROLE_ID
      ? [
          {
            title: 'Available',
            dataIndex: 'Buyable',
            key: 'Buyable',
            render: (elem) =>
              elem ? (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src="/vectors/green-check.png"
                    alt=""
                    style={{ width: '17px' }}
                  />
                </div>
              ) : (
                <div style={{ color: 'red', textAlign: 'center' }}>
                  <CloseOutlined />
                </div>
              ),
            filters: [
              { text: 'yes', value: true },
              { text: 'no', value: false },
            ],
            filterMultiple: false,
          },
        ]
      : []),
    {
      title: '',
      dataIndex: 'Buyable',
      key: 'Buyable',
      render: (text, rec) => (
        <Tooltip title="View" style={{ textAlign: 'center' }}>
          <Link to={`/products/product/${rec.id}`}>
            <img src="/vectors/eye.png" alt="" />
          </Link>
        </Tooltip>
      ),
      width: 50,
    },
  ];

  // columns.splice(1, 0, ...(props.roleId === roles.ADMIN_ROLE_ID ? adminColumns : notAdminColumns))

  return (
    <div id="Products-page">
      <h2 className="title">All Products</h2>
      <Spin spinning={count === null}>
        {count ? (
          <>
            {props.roleId === roles.ADMIN_ROLE_ID ? (
              <Link to={'/products/product/new'}>
                <div className="btn">Add Product</div>
              </Link>
            ) : (
              ''
            )}

            <Table
              columns={columns}
              title="products"
              link="products"
              scroll={{ x: props.roleId === roles.ADMIN_ROLE_ID ? 1500 : 1400 }}
            />
          </>
        ) : (
          <>
            <div className="empty-table">
              <div className="wrapper">
                <h3>— Your products is empty —</h3>
                {props.roleId === roles.ADMIN_ROLE_ID && (
                  <Link to={`/products/product/new`} style={{ color: 'white' }}>
                    <div className="btn">Add products</div>
                  </Link>
                )}
              </div>
            </div>
          </>
        )}
      </Spin>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    roleId: state.me.roleId,
    amCOOP: state.me.data.IsCoOp,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUrl: (url) => dispatch(setUrl(url)),
    setSelect: (q) => dispatch(setSelect(q)),
    setQuery: (q) => dispatch(setQuery(q)),

    setSelectedMenu: (id) => dispatch(setSelectedMenu(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsPage);
